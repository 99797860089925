@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.hot-card {
    text-decoration: none;
    height: 628px;
    width: 498px;
    cursor: pointer;
    /* background: rgb(1, 1, 0, 0.3); */
    background-image: linear-gradient(to bottom, transparent, black), url('/public/hot-card.png');
    filter:brightness(.7);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-size: 100% 100%;
    /* transition: opacity 1s ease-in-out; */
    /* transition: background-size .1s ease-in-out; */

    /* overflow: hidden; */

    -webkit-transition: all .6s linear;
    -moz-transition: all .6s linear;
    -o-transition: all .6s linear;
    transition: all .6s linear;
}

.hot-card-text-box {
    margin-top: 480px;
    margin-left: 25px;
    width: 420px;
    color: white;
    /* background-color: orange; */

}

.hot-card h1 {
    font-weight: 500;
    font-size: 36px;
    text-align: start;
    font-family: "Poppins";

    width: auto;
    /* background-color: aqua; */
    /* background-color: aqua; */
}

.hot-card p {
    color: white;
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 275;
    line-height: 28px;
    text-align: left;
    margin-top: -2px;
    width: auto;
    /* background-color: yellow; */
    /* background-color: aqua; */
}

.hot-card:hover {
    /* background: rgb(1, 1, 0, 0.3); */
    background-image: linear-gradient(to bottom, transparent, black 75%), url('/public/hot-card.png');
    background-size: 115% 115%;
}

/* 
.hot-card:hover h1 {
    
    margin-top: 535px;
   
} */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
    font-family: 'Resotho';
    src: url('https://ufmsports.s3.ap-south-1.amazonaws.com/Eclipticon/resources/fonts/Resotho-Extralight.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
}

.footer-container1 {
    height: 291px;
    width: 1440px;
    color: rgba(255, 255, 255, 1);
}

.footer-container1-text1 {
    width: 324px;
    height: 56px;
    margin-left: 346px;
    margin-top: 107px;

    font-family: 'Resotho';
    font-size: 48px;
    font-weight: 200;
    line-height: 56.16px;
    text-align: left;

    display: flex;
    color: rgba(255, 255, 255, 1);
}

.footer-container1-text2 {
    width: 327px;
    height: 33px;
    margin-left: 346px;
    gap: 0px;
    color: rgba(255, 255, 255, 1);
    font-family: 'Poppins';
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
}

.footer-container2 {
    height: 530px;
    width: 1440px;
    color: rgba(255, 255, 255, 1);
    background-image: linear-gradient(to bottom, transparent, black), url('/public/footer-bg.jpg');

    /* background-image: url('/public/footer-bg.jpg');  
    /* opacity: .38; */ 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.footer-container2-col1 {
    height: 530px;
    width: 209px;
}

.footer-container2-col2 {
    height: 530px;
    width: 512.5px;
}

.footer-container2-col3 {
    height: 530px;
    width: 514.5px;
}

.footer-container2-col4 {
    height: 530px;
    width: 203.5px;
}

.footer-container2-col2-row1 {
    height: 182px;
    width: 512.5px;
}

.footer-container2-col2-row2 {
    height: 146px;
    width: 512.5px;
}

.footer-container2-col4-row1 {
    height: 182px;
    width: 203.5px;
}



/* .findUsOn{
    height: 570px;
}

.findUsOn1{
    flex-direction: column;
    border-bottom: 2px solid white;
    height: 190px;
} */

.footer-icons-container {
    text-align: center;
    opacity: .54;
}

.footer-icons-container h6 {
    margin-top: 55px;
    margin-left: 26px;
    color: rgba(255, 255, 255, 1);

    font-family: "poppins";
    font-size: 20px;
    font-weight: 500;
    text-align: start;
}

.footer-icons-text {
    font-size: 16px;
    font-weight: 500;
    font-family: "poppins";
    text-align: start;
}

.footer-location-icon {
    display: flex;
    margin-left: 40px;
    margin-top: 30px;
}

.footer-location-text-box {
    height: 124px;
    width: 330px;
    margin-top: 30px;
    margin-left: 30px;
    opacity: .54;
}

.footer-phone-icon {
    display: flex;
    margin-left: 40px;
    margin-top: 30px;
}

.footer-phone-text-box {
    height: 74px;
    width: 247px;
    margin-top: 30px;
    margin-left: 30px;
    opacity: .54;
}

.footer-icons-box {
    height: 183.24px;
    width: 33.44px;
    margin-top: 36px;
    margin-left: 68px;
}

.footer-icon-linkedin {
    height: 31px;
    width: 27px;
    align-items: center;
    cursor: pointer;
}

.footer-icon-fb {
    height: 30px;
    width: 29px;
    margin-top: 16px;

    align-items: center;
    cursor: pointer;
}

.footer-icon-twitter {
    height: 30px;
    width: 30px;
    margin-top: 20px;
    align-items: center;
    cursor: pointer;
}

.footer-icon-insta {
    height: 31px;
    width: 31px;
    margin-top: 20px;
    align-items: center;
    cursor: pointer;
}


.footer-text {
    margin-top: 53px;
    font-family: 'poppins';
    font-weight: 500;
    font-size: 20px;
    
}

.footer-navigation-bar-box {
    height: auto;
    width: 182px;
    margin-left: 240px;
    margin-top: 264px;
    opacity: .54;
}

.footer-navigation-bar-box-text-style {
    text-decoration: none;
    font-family: 'Poppins';
    /* color: rgba(255, 255, 255, 1); */
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-align: end;
}

.footer-navigation-bar-margin {
    margin-top: 17px;
}
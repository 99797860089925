@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.product {
  height: 615px;
  width: 1440px;
  display: flex;
  flex-direction: row;
  font-family: "poppins";
  font-style: normal;
  color: rgba(255, 255, 255, 1);
}

.product-component1 {
  width: 116px;
}

.product-component2 {
  width: 116px;
}

.product-meta {
  width: 800px;
}

.product-meta-title {
  height: 60px;
  width: fit-content;
  margin-top: 75px;
  margin-left: 120px;
  font-weight: 300;
  size: 40px;
}

.product-meta-details {
  min-height: 150px;
  text-align: start;
  margin-top: 57px;
  margin-left: 120px;
  margin-right: 120px;
  size: 20px;
  font-weight: 300;
}

.product-image {
  height: 613px;
  width: 408px;
  opacity: 85%;
}

.Product-Meta-Details ul,
.Product-Meta-Details li {
  text-align: start;
}

.product-see-more {
  height: 88px;
  width: 242px;
  margin-top: 103px;
  margin-left: 281px;
  background: linear-gradient(to right, rgba(255, 255, 255, 1) 50%, rgba(0, 0, 0, 1) 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all .6s ease-out;
  color: rgba(255, 255, 255, 1);
  font-family: "poppins";
}

.product-see-more-without-hover {
  height: 88px;
  width: 242px;
  margin-top: 103px;
  margin-left: 281px;
  background-color: black;
  color: rgba(255, 255, 255, 1);
  font-family: "poppins";
}

.product-see-more-without-hover h1 {
  font-weight: 400;
  font-size: 24px;
  width: auto;
  height: 36px;
  margin-top: 28px;
  margin-left: auto;
  margin-right: auto;
  transition: margin-left 600ms;
}

.product-see-more:hover {
  color: rgba(0, 0, 0, 1);
  background-position: left bottom;
}

.product-see-more:hover h1 {
  margin-left: 38px;
}

.product-see-more h1 {
  font-weight: 400;
  font-size: 24px;
  /* width: 111px;
  height: 36px; */
  margin-top: 28px;
  margin-left: 60px;
  transition: margin-left 600ms;
}
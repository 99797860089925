@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'Resotho';
  src: url('https://ufmsports.s3.ap-south-1.amazonaws.com/Eclipticon/resources/fonts/Resotho-Extralight.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Resotho';
  src: url('https://ufmsports.s3.ap-south-1.amazonaws.com/Eclipticon/resources/fonts/Resotho-Extralight.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

.HomePageComponent {
    height: 617px;
    width:auto;
}

.HomePageComponent-why {
    height: 616px;
    width:auto;
}

.HomePageComponent-whatnew h1{
    text-align: center;    
    font-family: 'Lexend Deca', system-ui, -apple-system, BlinkMacSystemFont, 'Open Sans';
    margin-top: 50%;
    font-size: 48px;
    color: white;
}

.HomePage-who-are-we
{
  height: 1026px;
  color: white;
}

.HomePageComponent-who-are-we-component-row1
{
  height: 187px;
}

.HomePageComponent-who-are-we-component-row2
{
  height: 650px;
}




.HomePageComponent-why-eclipticon-component-row1 {
  color: aliceblue;
  border-bottom: 2px solid white;
  border-left: 2px solid white;
  height: 185px;
}
.HomePageComponent-why-eclipticon-component-row2 {
  color: aliceblue;
  border-left: 2px solid white;
  height: 431px;
}

.HomePageComponent-about-eclipticon
{
  height: 820px;
}

.HomePageComponent-about-eclipticon-component-row1 {
  color: white;
  height: 205px;
}

.HomePageComponent-about-eclipticon-component-row2 {
  color: white;
  height: 410px;
}

.why-eclipticon-container {
  height: 616px;
  width: 1440px;
  color: rgba(255, 255, 255, 1);
  font-family: "poppins";
  font-style: normal;
}

.why-eclipticon-th {
  width: 238px;
  height: 206px;
}

.why-eclipticon-th1 {
  width: 720px;
}


.why-eclipticon-td {
  width: 238px;
  height: 408px;
}
.why-eclipticon-td1 {
  width: 720px;
}

.why-eclipticon-title
{
  width: 337px;
  height: 72px;
  margin-top: 94px;
  margin-left: 374px;
}

.why-eclipticon-title h1
{
  font-weight: 275;
  font-size: 48px;
}

.why-eclipticon-text
{
  width: 636px;
  height: 216px;
  margin-left: 59px;
  margin-top: 71px;
}

.why-eclipticon-text h1
{
  font-weight: 275;
  font-size: 24px;
  text-align: right;
}

.why-eclipticon-img {
  width: 88px;
  margin-top: 78px;
  margin-left: 10px;
}

.bg-white{
  background-color: rgba(255, 255, 255, 1);
}

.who-are-we-container{
  height: 1026px;
  width: 1440px;
  color: rgba(255, 255, 255, 1);
  font-family: "poppins";
  font-style: normal;
}
.who-are-we-th {
  width: 238px;
  height: 206px;
}
.who-are-we-th1 {
  width: 1200px;
  height: 206px;
}

.who-are-we-td {
  width: 238px;
  height: 613px;
}

.who-are-we-td-test {
  width: 200px;
  height: 613px;
}

.who-are-we-td1 {
  width: 997px;
  height: 613px;
}
.who-are-we-td2 {
  width: 1235px;
  height: 203px;
}

.who-are-we-td21 {
  width: 200px;
  height: 203px;
}

.who-are-we-title {
  width: 350px;
  height: 72px;
  margin-left: 28px;
  margin-top: 124px;
}

.who-are-we-title h1 {
  font-weight: 300;
  font-size: 48px;
}

.who-are-we-text {
  width: 636px;
  height: 216px;
  margin-left: 68px;
}

.who-are-we-text h1 {
  font-weight: 275;
  font-size: 24px;
  text-align: left;
}



.about-eclipticon-container
{
  height: 820px;
  width: 1440px;
  color: white;
}

.about-eclipticon-col1
{
  height: 820px;
  width: 1032px;
}

.about-eclipticon-col1-row2
{
  height: 203px;
  width: 1032px;
  margin-top: 178px;
}

.about-eclipticon-col2
{
  height: 820px;
  width: 204px;
}

.about-eclipticon-col2-row1
{
  height: 410px;
  width: 204px;
}

.about-eclipticon-col3
{
  height: 820px;
  width: 204px;
}

.about-eclipticon-welcome-text-box
{
  height: 60px;
  width: 618px;
  margin-left: 172px;
  margin-top: 94px;
}

.about-eclipticon-welcome-text-box h1
{
  font-weight: 400;
  font-size: 40px;
  font-style: "poppins";
  text-align: start;
}

.about-eclipticon-company-name-text-box
{
  font-family: 'Resotho';
  height: 75px;
  width: 805px;
  margin-left: 78px;
  margin-top: 10px;
}

.about-eclipticon-company-name-line
{
  width: 255px;
  margin-top: 50px;
}

.about-eclipticon-company-name-text-box h1
{
  font-weight: 200;
  font-size: 64px;
  font-style: "poppins";
  font-stretch: ultra-expanded;;
  text-align: start;
}

.about-eclipticon-welcome-message-text-box
{
  height: 120px;
  width: 618px;
  margin-left: 172px;
  margin-top: 80px;
}

.about-eclipticon-welcome-message-text
{
  font-weight: 400;
  font-size: 40px;
  font-style: "Poppins";
  text-align: start;
}

.what-is-new-container
{
  height: 610px;
  width: 1440px;
  color: white;
}

.what-is-new-container-header-text-box
{
  height: 72px;
  width: 270px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 250px;
}

.what-is-new-container-image-box
{
  height: 600px;
  width: 490px;
  margin-top: 4px;
  margin-left: auto;
  margin-right: auto;
  background-image: 
  linear-gradient(
    rgba(0, 0, 0, 0)80%,rgba(0, 0, 0, 0)100%
  ),
  url('/public/wn.jpg');  background-size:70%;
  background-repeat:no-repeat;
  transition: 4s ease all;

}

.what-is-new-container-image-box:hover
{ 
   background-image:
   linear-gradient(to top, rgba(255,255,255,0) 20%,rgba(0,0,0,0) 100%),url('/public/wn.jpg'); 
  
  background-size:100%;
}

.what-is-new-container-text
{
  font-weight: 275;
  font-size: 48px;
  font-style: "Poppins";
}

.what-is-new-container-col1
{
  width: 238px;
}
.what-is-new-container-col2
{
  width: 496px;
}
.what-is-new-container-col3
{
  width: 500px;
}
.what-is-new-container-col4
{
  width: 204px;
}


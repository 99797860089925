.eclips {
    position: relative;
    justify-content: center;
    font-size: 60px;
    font-weight: 600;
    padding-top: 120px;
    width: 100%;
    text-align: center;    
  }
  
  .eclips h1 {
    letter-spacing: 20px;
    font-weight: 600;
    font-family: 'Montserrat';
    color: white;
  }
  
  .images img {
    position: absolute;
    padding-left: 630px;
    padding-top: 50px;
    text-align: center;
    display: flex;
    max-width: 100%;  
    height: auto;  
  }
  
  .comming {
    position: relative;
    text-align: center;
    padding-top: 23%;
    justify-content: center;
    width: 100%;
    font-size: xx-large;
    margin-bottom: 40px;
  }
  
  .comming h1 {
    letter-spacing: 10px;
    font-family: 'Montserrat';
    font-weight: 600;
    color: white;
  }
  
  .find {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
  }
  
  .find p {
    color: white;
    font-size: x-large;
    margin-right: 20px;
    font-family: 'Montserrat';
    font-weight: 200;
  }
  
  .icon-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .linked {
    height: 100%;
    max-width: 100%;
    margin-right: 20px;
  }
  
  .facebook {
    height: 100%;
    max-width: 100%;
    margin-right: 20px;
  }
  
  .twitter {
    height: 100%;
    max-width: 100%;
    margin-right: 20px;
  }
  
  .insta {
    height: 100%;
    max-width: 100%;
  }
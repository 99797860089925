@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.service-container {
    width: 1440px;
}

.service-title-box {
    height: 60px;
    width: 1082px;
    margin-left: 25px;
    margin-top: 65px;
}

.service-icon-box {
    height: 60px;
    width: 80px;
    margin-left: 70px;
    margin-top: 65px;
    color: white;
}

.service-title-text {
    font-family: "poppins";
    font-weight: 600;
    font-size: 40px;
    text-align: start;
    color: white;
}

.service-detail-box {
    height: 420px;
    width: 720px;
    margin-left: 71px;
    margin-top: 65px;
}

.service-detail-text {
    font-family: "poppins";
    font-weight: 300;
    font-size: 20px;
    color: white;
}

.service-detail-text p {
    text-align: start;
}

.service-detail-summary-box {
    min-height: 120px;
    width: 1310px;
    margin-left: 71px;
    margin-top: 60px;
    margin-bottom: 40px;
}

.service-image-box {
    height: 433px;
    width: 544px;
    margin-left: 50px;
    margin-top: 40px;
}

.service-image {
    height: 408px;
    width: 522px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 11px;
    background-image: url("/public/service.jpg");
}
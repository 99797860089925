@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.services-container
{
    width: 1440px;
    height: 1074px;
    margin-top: 0px;
}

.services-text-style
{
    font-family: "poppins";
    font-weight: 600;
    font-size: 24px;
    color: white;
    text-align: center;
    text-decoration: none; 
}

.services-summary-text-style
{
    font-family: "poppins";
    font-weight: 500;
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.services-container-message-box
{
    height: 60px;
    width: 1103px;
    margin-left: 169px;
    margin-top: 94px;    
}

.services-container-message-text
{
    font-family: "poppins";
    font-weight: 500;
    font-size: 20px;
    color: white;
    text-align: center;
}

.service-engineering-bg
{
    height: 408px;
    width: 480px;
    background-image: url("/public/engineering.jpg");
}

.service-engineering-bg-box
{
    height: 408px;
    width: 480px;

    display: block;

    -webkit-transition: all 500ms linear;
    -moz-transition: all 500ms linear;
      -o-transition: all 500ms linear;
         transition: all 500ms linear;
}

.service-engineering-bg-box:hover {
    background: rgb(1, 1, 0, 0.5);
}

.service-engineering-bg-box:hover .service-engineering-icon {
    height: 117px;
    width: 115px;
    margin-top: 88px;
}

.service-engineering-bg-box:hover .service-engineering-summary {
    display: block;
    animation: fade-in 1s;
}

.service-ds-bg
{
    height: 408px;
    width: 480px;
    background-image: url("/public/data-science.jpg");
}

.service-ds-bg-box
{
    height: 407px;
    width: 480px;

    display: block;

    -webkit-transition: all 500ms linear;
    -moz-transition: all 500ms linear;
      -o-transition: all 500ms linear;
         transition: all 500ms linear;
}

.service-ds-bg-box:hover {
    background: rgb(1, 1, 0, 0.5);
}

.service-ds-bg-box:hover .service-engineering-icon {
    height: 117px;
    width: 115px;
    margin-top: 88px;
}

.service-ds-bg-box:hover .service-ds-summary {
    display: block;
    animation: fade-in 1s;
}

.service-pm-bg
{
    height: 408px;
    width: 477px;
    background-image: url("/public/project-management.jpg");
}

.service-pm-bg-box
{
    height: 408px;
    width: 477px;
    display: block;

    -webkit-transition: all 500ms linear;
    -moz-transition: all 500ms linear;
      -o-transition: all 500ms linear;
         transition: all 500ms linear;
}

.service-pm-bg-box:hover {
    background: rgb(1, 1, 0, 0.5);
}

.service-pm-bg-box:hover .service-engineering-icon {
    height: 117px;
    width: 115px;
    margin-top: 88px;
}

.service-pm-bg-box:hover .service-pm-summary {
    display: block;
    animation: fade-in 1s;
}

.service-adm-bg
{
    height: 408px;
    width: 721px;
    background-image: url("/public/app-development.jpg");
}

.service-adm-bg-box
{
    height: 408px;
    width: 721px;
    display: block;

    -webkit-transition: all 500ms linear;
    -moz-transition: all 500ms linear;
      -o-transition: all 500ms linear;
         transition: all 500ms linear;
}

.service-adm-bg-box:hover {
    background: rgb(1, 1, 0, 0.5);
}

.service-adm-bg-box:hover .service-engineering-icon {
    height: 117px;
    width: 115px;
    margin-top: 88px;
}

.service-adm-bg-box:hover .service-adm-summary {
    display: block;
    animation: fade-in 1s;
}

.service-marketing-bg
{
    height: 408px;
    width: 479px;
    background-image: url("/public/marketing.jpg");
}

.service-marketing-bg-box
{
    height: 408px;
    width: 479px;
    display: block;

    -webkit-transition: all 500ms linear;
    -moz-transition: all 500ms linear;
      -o-transition: all 500ms linear;
         transition: all 500ms linear;
}

.service-marketing-bg-box:hover {
    background: rgb(1, 1, 0, 0.5);
}

.service-marketing-bg-box:hover .service-engineering-icon {
    height: 117px;
    width: 115px;
    margin-top: 88px;
}

.service-marketing-bg-box:hover .service-marketing-summary {
    display: block;
    animation: fade-in 1s;
}

.service-it-bg
{
    height: 408px;
    width: 238px;
    background-image: url("/public/it.jpg");
}

.service-it-bg-box
{
    height: 408px;
    width: 479px;
    display: block;

    -webkit-transition: all 500ms linear;
    -moz-transition: all 500ms linear;
      -o-transition: all 500ms linear;
         transition: all 500ms linear;
}

.service-marketing-it-box:hover {
    background: rgb(1, 1, 0, 0.5);
}

.service-marketing-it-box:hover .service-engineering-icon {
    height: 117px;
    width: 115px;
    margin-top: 88px;
}

.service-marketing-it-box:hover .service-it-summary {
    display: block;
    animation: fade-in 1s;
}



.service-engineering
{
    height: 408px;
    width: 480px;
    background-image: url("/public/engineering.jpg");
}

.service-engineering-icon
{
    height: 73px;
    width: 73px;
    margin-top: 150px;
    margin-left: auto;
    margin-right: auto;
    transition: 500ms;
}


.service-engineering-text
{
    height: 36px;
    width: 306px;
    margin-left: auto;
    margin-right: auto;
    text-decoration: none; 
    display: block;
}

.service-engineering-summary
{
    height: 48px;
    width: 394px;
    margin-left: auto;
    margin-right: auto;
    text-decoration: none; 
    display: none;
}

@keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }


.service-ds-icon
{
    height: 50px;
    width: 50px;
    margin-left: 205px;
    margin-top: 150px;
}

.service-ds-text
{
    height: 36px;
    width: 306px;
    margin-left: auto;
    margin-right: auto;
}

.service-ds-summary
{
    height: 48px;
    width: 394px;
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
    text-decoration: none; 
    display: none;
}

.service-ds:hover .service-engineering-icon {
    height: 117px;
    width: 115px;
    margin-top: 88px;
    transition: 500ms;
}

.service-ds:hover .service-ds-summary {
    display: block;
}


.service-pm-icon
{
    height: 57px;
    width: 56px;
    margin-left: 205px;
    margin-top: 150px;
}

.service-pm-text
{
    height: 36px;
    width: 306px;
    margin-left: auto;
    margin-right: auto;
}

.service-pm-summary
{
    height: 48px;
    width: 394px;
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
    text-decoration: none; 
    display: none;
}

.service-pm:hover .service-engineering-icon {
    height: 117px;
    width: 115px;
    margin-top: 88px;
}

.service-pm:hover .service-pm-summary {
    display: block;
}


.service-adm-icon
{
    height: 53px;
    width: 53px;
    margin-left: 205px;
    margin-top: 150px;
}

.service-adm-text
{
    height: 36px;
    width: 515px;
    margin-left: auto;
    margin-right: auto;
}

.service-adm-summary
{
    height: 48px;
    width: 394px;
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
    text-decoration: none; 
    display: none;
}

.service-marketing-icon
{
    height: 23px;
    width: 37px;
    margin-left: 205px;
    margin-top: 150px;
}

.service-marketing-text
{
    height: 36px;
    width: 306px;
    margin-left: auto;
    margin-right: auto;
}

.service-marketing-summary
{
    height: 48px;
    width: 394px;
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
    text-decoration: none; 
    display: none;
}


.service-it-icon
{
    height: 54px;
    width: 54px;
    margin-left: 205px;
    margin-top: 150px;
}

.service-it-text
{
    height: 36px;
    width: 152px;
    margin-left: 87px;
    margin-left: auto;
    margin-right: auto;
}

.service-it-summary
{
    height: 96px;
    width: 208px;
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
    text-decoration: none; 
    display: none;
}

.service-it:hover .service-engineering-icon {
    height: 117px;
    width: 115px;
    margin-top: 88px;
}

.service-it:hover .service-it-summary {
    display: block;
}

.component-row
{
    display: flex;
    flex-direction: row;
}

.component-col
{
    display: flex;
    flex-direction: column;
}
.service-title-icon-box{
    height: 250px;
}


@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.consulting-container {
    min-height: 817px;
    width: 1440px;
    color: rgba(255, 255, 255, 1);

}

.consulting-container-text-box {
    min-height: 267px;
    width: 756px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 93px;
}

.consulting-container-keypoints-text {
    font-weight: 300;
    font-size: 22px;
    font-family: "poppins";
    text-align: start;
}


.consulting-container-common-col1 {
    width: 209px;
}

.consulting-container-common-row2-col3-4 {
    min-height: 512px;
}

.consulting-container-common-col3 {
    width: 95px;
}

.consulting-container-common-col4 {
    width: 95px;
}

.consulting-container-icon-col3 {
    height: 205px;
    width: 204px;
}

.consulting-container-icon-box {
    height: 112px;
    width: 112px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 45px;
    margin-bottom: auto;
    display: flex;
}

.consulting-container-our-appraoch {
    height: 821px;
    width: 1440px;
    background-image: url('/public/consulting_bg.jpg');
}

.our-appraoch-text-box {
    height: 60px;
    width: fit-content;
    margin-left: 38px;
    margin-top: 117px;
}

.our-appraoch-text-box h1 {
    font-size: 40px;
    font-weight: 300;
    color: rgba(255, 255, 255, 1);
    font-family: "poppins";

}

.consulting-our-appraoch-row1 {
    height: 201px;
}

.consulting-our-appraoch-row2 {
    min-height: 618px;
}

.consulting-our-appraoch-row2-col2 {
    height: 310px;
    width: 513px;

    text-decoration: none;

    -webkit-transition: all 500ms linear;
    -moz-transition: all 500ms linear;
    -o-transition: all 500ms linear;
    transition: all 500ms linear;
}

.consulting-our-appraoch-row2-col2:hover {
    background: rgb(1, 1, 0, 0.25);
}

.consulting-our-appraoch-row2-col2:hover .our-appraochs-icon {
    height: 80px;
    width: 80px;

    margin-left: auto;
    margin-right: auto;
    margin-top: 90px;
}

.consulting-our-appraoch-row2-col2:hover .our-appraochs-text {
    margin-top: 20px;
}

.consulting-our-appraoch-col2 {
    width: 1027px;
}

.consulting-our-appraoch-col2-half-line {
    margin-top: 23px;
    width: 454px;
    animation-duration: 1s;
    animation-name: slidein;
}

@keyframes slidein {
    from {
        translate: 150vw 0;
        scale: 200% 1;
    }

    to {
        translate: 0 0;
        scale: 100% 1;
    }
}

.our-appraochs-icon {
    height: 58px;
    width: 58px;

    margin-left: auto;
    margin-right: auto;
    margin-top: 104px;

    transition: 500ms;
}

.our-appraochs-text {
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    width: fit-content;
    height: fit-content;
    color: rgba(255, 255, 255, 1);

    transition: 500ms;
}

.our-appraochs-text h1 {
    font-weight: 300;
    font-size: 22px;
    font-family: "poppins";
    text-align: start;
}

.consulting-container-see-more {
    height: 88px;
    width: 242px;
    margin-top: 84px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 93px;
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 50%, rgba(0, 0, 0, 1) 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .6s ease-out;
    color: rgba(255, 255, 255, 1);
    font-family: "poppins";
}

.consulting-container-see-more:hover {
    color: rgba(0, 0, 0, 1);
    background-position: left bottom;
}

.consulting-container-see-more-arrow {
    width: 43px;
    margin-top: 34px;
    margin-left: 10px;
    /* display: none; */
}

/* .consulting-container-see-more:hover .consulting-container-see-more-arrow {
   display: block;
  } */

.consulting-container-see-more:hover h1 {
    margin-left: 42px;
    margin-top: 28px
}

.consulting-container-see-more h1 {
    font-weight: 400;
    font-size: 24px;
    width: 111px;
    height: 36px;
    margin-top: 28px;
    margin-left: 70px;
    transition: margin-left 600ms;
}

.consulting-container-keypoints-text ul {
    line-height: 180%;
}

.consulting-container-keypoints-text ul li {
    margin-top: 0.9px;
}
.border-r
{
  border-right: 2px solid  rgba(88, 88, 88,1);
  /* border-right: 2px solid rgba(255, 255, 255, 1) */
  /* border-right: 2px solid orange; */
}

.border-l
{
  border-left: 2px solid  rgba(88, 88, 88,1);
  /* border-left: 2px solid rgba(255, 255, 255, 1) */
  /* border-left: 2px solid orange; */
}

.border-t
{
  border-top: 2px solid  rgba(88, 88, 88,1);
  /* border-top: 2px solid rgba(255, 255, 255, 1); */
  /* border-top: 2px solid  orange; */
}

.border-b
{
  border-bottom: 2px solid  rgba(88, 88, 88,1);
  /* border-bottom: 2px solid rgba(255, 255, 255, 1) */
  /* border-bottom: 2px solid  orange; */
}

.product-see-more-button-border
{
  border-right:2px solid  rgba(255, 255, 255, 1);
  border-left: 2px solid  rgba(255, 255, 255, 1);
  border-top: 2px solid  rgba(255, 255, 255, 1);
  border-bottom: 2px solid  rgba(255, 255, 255, 1);
}

.component-row
{
    display: flex;
    flex-direction: row;
}

.component-col
{
    display: flex;
    flex-direction: column;
}

.background-black
{
  background-color: black;
}

.background-white
{
  background-color: white;
}

.background-off-white{
  background-color: rgba(217, 217, 217, 1);
}

.border-r-black
{
  border-right: 2px solid  black;
  /* border-right: 2px solid rgba(255, 255, 255, 1) */
  /* border-right: 2px solid orange; */
}

.border-l-black
{
  border-left: 2px solid  black
  /* border-left: 2px solid rgba(255, 255, 255, 1) */
  /* border-left: 2px solid orange; */
}

.border-t-black
{
  border-top: 2px solid black;
  /* border-top: 2px solid rgba(255, 255, 255, 1); */
  /* border-top: 2px solid  orange; */
}
.border-b-black
{
  border-bottom: 2px solid black;
  /* border-top: 2px solid rgba(255, 255, 255, 1); */
  /* border-top: 2px solid  orange; */
}

.button-see-more {
  height: 88px;
  width: 242px;
  margin-top: 107px;
  margin-left: 180px;
  margin-right: auto;
  background: linear-gradient(to right, rgba(255, 255, 255, 1) 50%,  rgba(0, 0, 0,1) 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all .6s ease-out;
  color:  rgba(255, 255, 255, 1);
  font-family: "poppins";
  background-color: aqua;
}

.button-see-more:hover {
  color: rgba(0, 0, 0,1);
  background-position: left bottom;
}

.button-see-more-arrow {
  width: 43px;
  margin-top: 34px;
  margin-left: 10px;
  /* display: none; */
}

.button-see-more:hover h1 {
  margin-left: 42px;
  margin-top:28px;
  color: black;
}

.button-see-more h1{
  font-weight: 400;
  font-size: 24px;
  width: 111px;
  height: 36px;
  margin-top: 28px;
  margin-left: 70px;
  transition: all 600ms;
} 
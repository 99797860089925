@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.why-eclipticon-container {
    height: 616px;
    width: 1440px;
    color: rgba(255, 255, 255, 1);
    font-family: "poppins";
    font-style: normal;
  }

  .why-eclipticon-container-row1 {
    height: 206px;
  }

  .why-eclipticon-container-row2 {
    height: 410px;
  }

  .why-eclipticon-container-col1 {
    width: 240px;
  }

  .why-eclipticon-container-col2 {
    width: 760px;
  }

  .why-eclipticon-container-col3 {
    width: 240px;
  }

  .why-eclipticon-container-col4 {
    width: 200px;
  }

.why-eclipticon-title-box
{
  width: 337px;
  height: 72px;
  margin-top: 94px;
  margin-left: 408px;
}

.why-eclipticon-title-box h1
{
  font-weight: 275;
  font-size: 48px;
}


.why-eclipticon-details-box
{
  width: 636px;
  height: 216px;
  margin-left: 89px;
  margin-top: 71px;
}

.why-eclipticon-details-box h1
{
  font-weight: 275;
  font-size: 24px;
  text-align: right;
}


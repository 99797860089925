@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.compony {
  color: rgba(255, 255, 255, 1);
  font-family: "poppins";
  display: flex;
  flex-direction: row;
}

.about-container {
  color: rgba(255, 255, 255, 1);
  height: 724px;
  width: 1440px;
  display: flex;
  flex-direction: row;
}

.about {
  height: 724px;
  width: 1200px;
  display: flex;
  flex-direction: column;
  text-align: start;
}

.about-header {
  font-family: "poppins";
  font-style: normal;
  height: 215px;
  display: flex;
  flex-direction: row;
}

.about-header h1 {
  height: 60px;
  width: 343px;
  font-weight: 300;
  font-size: 40px;
  margin-top: 92px;
  margin-left: 25px;
}

.about-text {
  font-weight: 300;
  font-size: 22px;
}

.about-text p {
  font-weight: 300;
  font-size: 22px;
  height: 330px;
  width: 902px;
  margin-left: 129px;
  margin-top: 124px;

}

.team-component {
  display: flex;
  flex-direction: column;
}

.our-team-container {
  height: 215px;
  width: 1440px;
  color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: row;
}

.our-team-text {
  margin-top: 92px;
  margin-left: 25px;
  font-family: "poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
}

.team-member {
  height: 388px;
  width: 1440px;
  display: flex;
  flex-direction: row;
  color: rgba(255, 255, 255, 1);
}

.blank-component {
  width: 116px;
}

.blank-component1 {
  width: 90px;
}

.team-member-profile-img {
  width: 387px;
  opacity: 70%;
}

.img-p {
  width: 385px;
  height: 385px;
  object-fit: cover;
  object-position: center 15%;
  border-radius: 50%;
}


.team-member-profile-img-bg {
  background-color: rgba(255, 255, 255, 1);
}

.team-member-profile-details {
  width: 705px;
  font-family: "poppins";
  font-style: normal;
}

.team-member-profile-details-name {
  width: fit-content;
  font-weight: 600;
  font-size: 32px;
  margin-top: 44px;
  margin-left: 56px;
}

.team-member-profile-details-designation {
  width: fit-content;
  margin-left: 56px;
  margin-top: 20px;
  font-weight: 300;
  font-size: 24px;
}

.team-member-profile-details-about {
  margin-left: 56px;
  margin-top: 40px;
  height: 150px;
  width: 600px;
  font-weight: 300;
  font-size: 20px;
  text-align: start;
}

.align-left {
  text-align: start;
}

.align-right {
  text-align: end;
}
@font-face {
    font-family: 'Resotho';
    src: url('https://ufmsports.s3.ap-south-1.amazonaws.com/Eclipticon/resources/fonts/Resotho-Extralight.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Resotho';
    src: url('https://ufmsports.s3.ap-south-1.amazonaws.com/Eclipticon/resources/fonts/Resotho-Extralight.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
}

.navigationbar-container {
    width: 1440px;
    height: 205px;
    display: flex;
    flex-direction: row;
    color: white
}

.navigationbar-home-icon-container {
    width: 420px;
    height: 205px;
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: row;
}

.navigationbar-home-icon1 {
    width: 46px;
    height: 55px;
    margin-top: 79px;
    margin-left: 61px;
}


.navigationbar-home-text {
    font-family: 'Resotho';
    width: 227px;
    height: 28px;
    margin-top: 97px;
    margin-left: 14px;
    font-weight: 200;
    font-size: 24px;
    line-height: 28.08px;
    letter-spacing: 0.18em;
    text-align: left;
}

.navigationbar-link:hover {
    color: black;
    background-color: rgba(255, 255, 255, 1);
    transition: all .9s ease;
    -webkit-transition: all .9s ease;
}

.navigationbar-link {
    width: 204px;
    height: 205px;
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
}

.navigationbar-link h4 {
    margin-top: 86px;
    text-align: center;
    size: 24px;
    font-weight: 600;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.what-is-new-container
{
  height: 640px;
  width: 1440px;
  color: rgba(255, 255, 255, 1);
}

.what-is-new-container-col1
{
  transform-origin: 0 100%;
  width: 240px;
}

.what-is-new-container-col2
{
  width: 1002px;
}

.what-is-new-container-col3
{
  width: 198px;
}

.what-is-new-container-col21
{
    height: 640px;
    width: 482px;
}

.what-is-new-container-col22
{
    transform-origin: 100% 0;
    height: 640px;
    width: 518px;
}

.what-is-new-container-hotcard-box
{
    height: 628px;
    width: 498px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 6px;
}

.what-is-new-container-text-box
{
    width: 270px;
    height: 72px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 320px;
}

.what-is-new-container-text-box h1
{
    font-family: 'Poppins';
    font-size: 48px;
    font-weight: 275;
    line-height: 72px;
    text-align: center;
}


